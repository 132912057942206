
<template xmlns:src="http://www.w3.org/1999/xhtml" xmlns:v-bind="http://www.w3.org/1999/xhtml"
          xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="col s12 no-padding">

        <div v-if="!mustRefreshCustomer" class="col s12 vert-offset-top-1 padding-top-half">
            <div v-if="hasCustomer" class="row col s12 vert-offset-top-1">
                <div class="col s12">

                        <div v-if="hasInvoices" class="col s12 no-padding">
                            <div class="col s12 font-bold font-16 vert-offset-top-1 padding-bottom-1 grey-border border-bottom-1 border-lighten-2">
                                Invoices
                            </div>
                            <div class="col s12 padding-top-1">
                                <table class="font-10">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Amount ({{country.currency.code}})</th>
                                        <th>Due Date</th>
                                        <th>Min. Payment ({{country.currency.code}})</th>
                                        <th>Penalty</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(_item, _index) in invoices" :key="_index">
                                        <td>{{ _index + 1}}</td>
                                        <td>{{ _item.opening_date}}</td>
                                        <td>{{ _item.closing_date}}</td>
                                        <td>{{ _item.amount }}</td>
                                        <td>{{ _item.due_date}}</td>
                                        <td>{{ _item.min_payment}}</td>
                                        <td>{{ _item.penalty }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div
                                    class="col s12 vert-offset-top-half padding-top-1 grey-border border-bottom-1 border-lighten-2 center">
                                <button
                                        v-if="showNext && downloaded"
                                        class="btn btn-tiny gold-text black"
                                        @click.prevent="getListInvoices()"
                                >More</button>

                                <button
                                        v-if="downloaded && hasInvoices && !showNext"
                                        class="btn btn-tiny gold-text black"
                                        @click.prevent="resetListInvoices()"
                                >Refresh</button>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
</template>
<script>
    // @ is an alias to /src
    import {mapState} from "vuex";
    import M from "materialize-css";
    /*import moment from 'moment';*/


    export default {
        name: "HomeFoodCreditComponent",
        components:{
        },
        data() {
            return {
                downloaded: false,
                pagination: null,
                links: null,
                triggerTooltip:false,
            }
        },
        created () {

        },
        mounted() {
            this.getListInvoices();
        },
        methods : {

            scroll ()
            {
                let _thisObj = this;
                window.onscroll = () =>
                {
                    /*window.console.log("ScrollTop: "+ document.documentElement.scrollTop);*/
                    let _refresh = null;
                    if((document.documentElement.scrollTop === 0) && (_thisObj.scrollPoint > 0))
                    {
                        if(_thisObj.new_content)
                        {
                            _refresh = true;
                        }
                    }
                    let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                    if ((bottomOfWindow && _thisObj.showNext)||_refresh === true)
                    {
                        _thisObj.getList(_refresh);
                    }
                    _thisObj.scrollPoint = document.documentElement.scrollTop;
                };
            },
            resetListInvoices: function()
            {
                window.scrollTo(0, 0);
                this.pagination = null;
                this.links = null;
                this.getListInvoices({});
            },
            getListInvoices: function(_formData)
            {
                let _thisObj = this;
                if(_thisObj.hasAccount)
                {
                    if(typeof _formData === "undefined" || _formData === null)
                    {
                        _formData = {
                            page: _thisObj.next_page,
                            customer: _thisObj.customer.key,
                            account: _thisObj.account.key,
                        };
                    }
                    window.console.log("Invoices formData: " +JSON.stringify(_formData));
                    _thisObj.$store.dispatch('FC_GET_CUSTOMER_INVOICES',_formData)
                        .then(function(_response)
                        {
                            if(typeof _response.data.extra !== "undefined" && _response.data.extra !== null)
                            {
                                let _i = 0;
                                if(typeof _response.data.extra.outlets !== "undefined" && _response.data.extra.outlets !== null && _response.data.extra.outlets.length > 0)
                                {
                                    for(_i=0;_i<_response.data.extra.outlets.length;_i++)
                                    {
                                        if(_thisObj.arr_outlets.indexOf(_response.data.extra.outlets[_i]))
                                        {
                                            _thisObj.arr_outlets.push(_response.data.extra.outlets[_i]);
                                        }
                                    }
                                }
                                if(typeof _response.data.extra.devices !== "undefined" && _response.data.extra.devices !== null && _response.data.extra.devices.length > 0)
                                {
                                    for(_i=0;_i<_response.data.extra.devices.length;_i++)
                                    {
                                        if(_thisObj.arr_devices.indexOf(_response.data.extra.devices[_i]))
                                        {
                                            _thisObj.arr_devices.push(_response.data.extra.devices[_i]);
                                        }
                                    }
                                }

                            }
                            /*window.console.log("START-CUSTOMER REPORT----");
                             window.console.log(JSON.stringify(_response));
                             window.console.log("END-CUSTOMER REPORT----");*/

                            _thisObj.downloaded = true;
                            if(typeof _response.data.pagination !== "undefined" && _response.data.pagination !== null)
                            {
                                _thisObj.pagination = _response.data.pagination;
                            }
                            if(typeof _response.data.links !== "undefined" && _response.data.links !== null)
                            {
                                _thisObj.links = _response.data.links;
                            }
                            /*_thisObj.$store.dispatch('SET_TRIGGER_TOOLTIP',true);*/
                        });
                }
                else
                {
                    window.console.log("No Account Data Found");
                }
            },
        },
        computed: {
            ...mapState({
                customer:state=>state.foodcredit.customer,
                account:state=>state.foodcredit.account,
                activated:state=>state.foodcredit.active,
                confirmed:state=>state.foodcredit.confirmed,
                selected_service:state=>state.customers.customer_selected_service,
                msisdn: state => state.customers.msisdn,
                processing: state => state.processing,
                country: state => state.customers.country,
                invoices: state => state.foodcredit.paginated_list_invoices,
            }),

            isProcessing: function()
            {
                return (typeof this.processing !== "undefined" && this.processing !== null && this.processing > 0);
            },
            hasCustomer: function()
            {
                return (typeof this.customer !== "undefined" && this.customer !== null);
            },
            hasAccount: function()
            {
                return (this.hasCustomer && typeof this.account !== "undefined" && this.account !== null);
            },
            hasTransactions: function()
            {
                return (this.hasAccount && typeof this.transactions !== "undefined" && this.transactions !== null && this.transactions.length > 0);
            },
            hasInvoices: function()
            {
                return (this.hasAccount && typeof this.invoices !== "undefined" && this.invoices !== null && this.invoices.length > 0);
            },
            isConfirmed: function()
            {
                return (this.hasCustomer && typeof this.confirmed !== "undefined" && this.confirmed !== null && this.confirmed === true);
            },
            isActivated: function()
            {
                return (this.hasCustomer && typeof this.activated !== "undefined" && this.activated !== null && this.activated === true);
            },
            mustRefreshCustomer: function()
            {
                return (!this.isProcessing && !this.hasCustomer);
            },
            current_page: function()
            {
                if(!this.hasTransactions)
                {
                    return null;
                }
                if(typeof this.pagination === "undefined" || this.pagination === null)
                {
                    return null;
                }
                if(typeof this.pagination.current_page === "undefined" || this.pagination.current_page === null)
                {
                    return null;
                }
                return this.pagination.current_page;
            },
            total_pages: function()
            {
                if(!this.hasInvoices)
                {
                    return null;
                }
                if(typeof this.pagination === "undefined" || this.pagination === null)
                {
                    return null;
                }
                if(typeof this.pagination.total_pages === "undefined" || this.pagination.total_pages === null)
                {
                    return null;
                }
                return this.pagination.total_pages;
            },
            next_page: function()
            {
                if(typeof this.current_page === "undefined" || this.current_page === null)
                    return null;

                if(typeof this.total_pages === "undefined" || this.total_pages === null)
                    return null;

                if(this.total_pages <= this.current_page)
                    return null;

                return (this.current_page+1);
            },
            showNext:function ()
            {
                return (typeof this.next_page !== "undefined" && this.next_page !== null);
            },
        },


        watch: {
        }
    };
</script>